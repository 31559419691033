<script>
import AdminSpinner from '@/admin/components/AdminSpinner';
import UserForm from '@/admin/components/users/UserForm';

export default {
  components: { UserForm, AdminSpinner },
  metaInfo: { title: 'Register' },
  data: () => ({ status: false, user: '' }),

  computed: {
    isRegister() {
      return this.$route.meta.mode === 'register';
    },
    isReset() {
      return this.$route.meta.mode === 'reset';
    },
    ready() {
      return !!this.status;
    },
  },

  methods: {
    update() {
      this.$api.post('auth/self', this.user).then(response => {
        this.$store.dispatch('logIn', response.data);
      });
    },
  },

  created() {
    localStorage.removeItem('token');

    this.$api
      .post('auth/token', this.$route.params)
      .then(({ data }) => {
        if (data.token) {
          localStorage.setItem('token', data.token);
          this.user = data;
          this.status = 'ready';
        } else {
          this.status = 'done';
        }
      })
      .catch(e => {
        this.status = 'fail';
      });
  },
};
</script>

<template>
  <AdminSpinner :ready="ready" hide>
    <h2 class="head-2xl mb-5 text-center">
      <template v-if="isRegister">Register</template>
      <template v-if="isReset">Password Reset</template>
    </h2>

    <UserForm v-if="status === 'ready'" :value="user" :register="isRegister" :reset="isReset" @update="update" />
    <div v-else-if="status === 'done'" class="note note-success">
      <p><strong>This user has already registered</strong></p>
      <p>
        Please
        <RouterLink to="/login">sign in.</RouterLink>
      </p>
    </div>
    <div v-else class="note note-error">
      <p><strong>This link has expired</strong></p>
      <p>Make sure you have used the link from the most recent email.</p>
      <p v-if="isRegister">
        If you have already registered please
        <RouterLink to="/login">sign in</RouterLink>
        <span>.</span>
      </p>
      <p v-else-if="isReset">
        You can request a new
        <RouterLink to="/login/reset">password reset email</RouterLink>
        <span>.</span>
      </p>
    </div>
  </AdminSpinner>
</template>
