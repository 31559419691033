<script>
import { mapState } from 'vuex';
import '@/assets/svgs/icons/tick.svg';
import '@/assets/svgs/icons/warn.svg';
import '@/assets/svgs/icons/ring-add.svg';

export default {
  props: ['user'],
  data: () => ({ ready: true, synced: false }),

  computed: {
    enabled() {
      return !this.user.synced && !!this.user.email;
    },
    synced$() {
      return this.synced || this.user.synced;
    },
    colour() {
      if (this.synced$) return 'text-pe';
      if (!this.user.email) return 'text-fun-red-shade';
      return 'text-pe-shade';
    },
    icon() {
      if (this.synced$) return 'tick';
      if (!this.user.email) return 'warn';
      return 'ring-add';
    },
    ...mapState('account', ['wondeId']),
  },

  methods: {
    add() {
      if (!this.enabled) return;

      this.ready = false;
      this.$api.post(`wonde/${this.wondeId}/employees/${this.user.wondeId}`).then(res => {
        this.$emit('update');
        this.ready = true;
        this.synced = true;
      });
    },
  },
};
</script>

<template>
  <component :is="enabled ? 'button' : 'div'" :class="colour" class="flex items-center card" @click="add">
    <div class="text-black overflow-hidden flex-grow">
      {{ user.name }}
      <div class="text-xs truncate">
        <span v-if="user.email" :title="user.email">{{ user.email }}</span>
        <span v-else class="text-fun-red">User missing email</span>
      </div>
    </div>

    <div class="shrink-0">
      <div v-if="!ready" class="mini-spinner spin08 pe"></div>
      <svg v-else-if="icon" class="w-5 h-5 fill-pe"><use :xlink:href="`#${icon}`" /></svg>
    </div>
  </component>
</template>
