<script>
import AdminModal from '@/admin/components/AdminModal';
import TrialBox from '@/global/components/TrialBox';
import Brand from '@/global/components/Brand';
import Vimeo from '@/global/components/Vimeo2';
import Link from '@/global/components/Link2';
import '@/assets/svgs/icons/eh-arrow.svg';
import '@/assets/svgs/dashboard/account.svg';
import '@/assets/svgs/dashboard/free-trial.svg';
import '@/assets/svgs/dashboard/news-events.svg';
import '@/assets/svgs/dashboard/refer-a-friend.svg';

import { mapState, mapGetters } from 'vuex';

const videos = {
  welcome: { id: '947025953', title: 'Welcome to real PE membership' },
  realpe: { id: '953593392', title: 'real PE programme overview' },
  bursts: { id: '828940517', title: 'BURSTS overview' },
  learning: { id: '955983588', title: 'Learning Hub overview' },
};

export default {
  components: { Vimeo, AdminModal, Brand, Link, TrialBox },
  metaInfo: { title: 'Dashboard' },
  data: () => ({ videoId: null, currentHighlight: 0, timer: null }),

  computed: {
    video() {
      return videos[this.videoId] || {};
    },

    alerts() {
      return this.dashboard.alerts?.map(alert => ({ ...alert, ...alert.link[0] })) || [];
    },
    highlights() {
      return this.dashboard.highlights?.map(item => ({ ...item, ...item.link[0], img: item.image[0]?.url })) || [];
    },

    leads() {
      return this.account.realLeads?.map(lead => `${lead.firstName} ${lead.lastName}`);
    },

    ...mapState(['dashboard', 'account']),
    ...mapGetters('user', ['isAdmin', 'isCreate', 'isHome']),
    ...mapGetters('account', ['uri', 'trialExpRealPe', 'trialDaysRealPe', 'renewalRealPe']),
    ...mapGetters(['hasBursts', 'hasRealPe', 'hasRealPeTrial', 'hasRealPeFull', 'pfwUrl']),
  },

  methods: {
    checkZohoID() {
      this.$api.get(`accounts/zoho/${this.$route.query.zid}`).then(({ data }) => {
        this.$router.replace(`/accounts/${data.id}`);
      });
    },

    setInterval() {
      this.timer = setInterval(() => {
        this.currentHighlight = (this.currentHighlight + 1) % this.highlights.length;
      }, 10000);
    },
    clearInterval() {
      clearInterval(this.timer);
    },

    changeHighlight(i) {
      this.clearInterval();
      this.currentHighlight = i;
      this.setInterval();
    },
  },

  created() {
    if (this.$route.query.zid) this.checkZohoID();
    if (this.isHome) this.$router.replace('/home');

    // this.setInterval();
  },

  beforeDestroy() {
    this.clearInterval();
  },
};
</script>

<template>
  <main>
    <div class="container my-10">
      <!-- alerts -->
      <div v-if="alerts.length" class="mb-6 -mt-4">
        <Link
          v-for="alert in alerts"
          :key="alert.id"
          :uri="alert.url"
          :newTab="alert.newWindow"
          :class="`note-${alert.level}`"
          class="note mb-3 text-base block"
        >
          <svg class="note-icon">
            <use v-if="alert.level === 'error'" xlink:href="#warn" />
            <use v-else xlink:href="#info" />
          </svg>
          <div v-html="alert.text" class="mr-5"></div>
          <svg v-if="alert.url" class="w-3 h-3 mr-10 mt-4 note-icon">
            <use xlink:href="#eh-arrow" />
          </svg>
        </Link>
      </div>
      <!-- TW: note-plain note-warn note-error -->

      <div class="mb-10 mt-8">
        <div class="flex">
          <div>
            <h1 class="head-4xl leading-none font-bliss">Welcome to real PE membership</h1>
          </div>
          <button class="dbv block border-pe bg-white m-0 ml-6" @click="videoId = 'welcome'">
            Guide
            <svg class="btn4-icon"><use xlink:href="#video" /></svg>
          </button>
        </div>
      </div>

      <!-- real pe -->
      <div class="flex flex-wrap -mx-2">
        <div id="home-menu-pe" class="w-1/2 lg:w-1/3 px-2 pb-4 relative">
          <RouterLink to="/real" class="dash-block border-pe pb-16">
            <Brand slug="full" class="h-16 mb-3" />
            <p class="mb-3">Planning, teaching and assessment</p>

            <svg class="w-8 h-8 fill-grey print:hidden absolute top-0 right-0 m-4">
              <use xlink:href="#arrow" />
            </svg>
          </RouterLink>

          <button class="dbv absolute bottom-0 left-0 ml-6 mb-9" @click="videoId = 'realpe'">
            Guide
            <svg class="btn4-icon"><use xlink:href="#video" /></svg>
          </button>
        </div>

        <!-- bursts -->
        <div id="home-menu-bursts" class="w-1/2 lg:w-1/3 px-2 pb-4 relative">
          <RouterLink to="/bursts" class="dash-block border-bursts pb-16">
            <img src="/brands/bursts.svg" alt="" class="h-16 mb-3" />
            <p class="mb-2">EYFS/KS1 home learning - onboarding, data and impact</p>

            <svg class="w-8 h-8 fill-grey print:hidden absolute top-0 right-0 m-4">
              <use xlink:href="#arrow" />
            </svg>
          </RouterLink>

          <button class="dbv absolute bottom-0 left-0 ml-6 mb-9" @click="videoId = 'bursts'">
            Guide
            <svg class="btn4-icon"><use xlink:href="#video" /></svg>
          </button>
        </div>

        <!-- learning hub -->
        <div id="home-menu-learning" class="w-1/2 lg:w-1/3 px-2 pb-4 relative">
          <RouterLink to="/learning" class="dash-block border-learning pb-16">
            <div class="dash-block-title h-16 mb-3 justify-start">
              <img src="/brands/learning.svg" alt="Learning Hub" class="h-16" />
            </div>
            <p class="mb-2">A range of professional learning to develop confidence and expertise</p>

            <svg class="w-8 h-8 fill-grey print:hidden absolute top-0 right-0 m-4">
              <use xlink:href="#arrow" />
            </svg>
          </RouterLink>

          <button class="dbv absolute bottom-0 left-0 ml-6 mb-9" @click="videoId = 'learning'">
            Guide
            <svg class="btn4-icon"><use xlink:href="#video" /></svg>
          </button>
        </div>

        <!-- Highlights -->
        <div id="home-menu-highlights" class="w-1/2 lg:w-1/3 px-2 pb-4">
          <div class="dash-block">
            <div class="dash-block-title">
              Member Highlights
              <svg class="h-8 w-8 -mt-1"><use :xlink:href="`#news-events`" /></svg>
            </div>
            <p class="mb-4">Announcements, news and updates</p>

            <Link
              v-for="(item, i) in highlights"
              :key="item.title"
              :uri="item.url"
              :newTab="item.newWindow"
              v-show="i === currentHighlight"
              class="flex mt-2 overflow-hidden outlne border-4 border-pe rounded-tl-3xl rounded-br-3xl"
              style="min-height: 100px"
            >
              <div :style="`background-image: url(${item.img});`" class="w-2/5 bg-center bg-cover shrink-0"></div>
              <!-- <img v-if="item.img" :src="item.img" alt="" class="w-2/5" /> -->
              <div class="text-pe font-medium p-2 px-3 text-xl leading-tight self-center">
                {{ item.title }}
              </div>
            </Link>

            <div class="flex items-center justify-center">
              <div
                v-for="(item, i) in highlights"
                :class="{ 'w-6 bg-pe': i === currentHighlight }"
                class="rounded-lg border-2 border-pe pt-1 mx-0.5 w-3 mt-2 overflow-hidden outlne"
                @click="changeHighlight(i)"
              ></div>
            </div>
          </div>
        </div>

        <!-- account -->
        <div id="home-menu-account" class="w-1/2 lg:w-1/3 px-2 pb-4">
          <div class="dash-block">
            <div class="dash-block-title">
              Account
              <svg class="h-8 w-8 -mt-1"><use :xlink:href="`#account`" /></svg>
            </div>

            <p class="mb-2">Membership information, manage account and users</p>

            <div v-if="!isCreate" class="flex -mx-2">
              <div v-if="leads" class="w-1/2 px-2">
                <div class="font-bold">real PE Leads</div>
                <div v-for="lead in leads">{{ lead }}</div>
              </div>
              <div v-if="renewalRealPe" class="w-1/2 px-2">
                <div class="font-bold">real PE renewal</div>
                {{ renewalRealPe }}
              </div>
            </div>

            <div class="flex flex-wrap -m-1 mt-3">
              <template v-if="isCreate">
                <RouterLink to="/accounts" class="db1">Accounts</RouterLink>
                <RouterLink to="/user-search" class="db1">User Search</RouterLink>
                <RouterLink to="/create" class="db1">Create</RouterLink>
              </template>
              <template v-else-if="isAdmin && hasRealPeFull">
                <RouterLink :to="`${uri}users`" class="db1">Users</RouterLink>
                <RouterLink :to="`${uri}home`" class="db1">Home Learning</RouterLink>
                <RouterLink :to="`${uri}training`" class="db1">Training</RouterLink>
                <!-- Training -->
              </template>
              <!-- <button class="db1" @click="videoId = 'realpe'">
                Guide
                <svg class="btn4-icon"><use xlink:href="#video" /></svg>
              </button> -->
            </div>
          </div>
        </div>

        <!-- refer a friend -->
        <div v-if="hasRealPeFull" id="home-menu-refer" class="w-1/2 lg:w-1/3 px-2 pb-4">
          <div class="dash-block">
            <div class="dash-block-title">
              Refer a Friend
              <svg class="h-8 w-8 -mt-1"><use :xlink:href="`#refer-a-friend`" /></svg>
            </div>

            <p class="mb-4">£100 membership discount when a school you refer joins</p>

            <div class="flex flex-wrap -m-1">
              <a :href="`${pfwUrl}refer-a-friend`" target="_blank" class="db1">Refer here</a>
            </div>
          </div>
        </div>

        <!-- trial -->
        <div v-else-if="trialExpRealPe" id="home-menu-trial" class="w-1/2 lg:w-1/3 px-2 pb-4">
          <div class="dash-block">
            <div class="dash-block-title">
              Trial
              <svg class="h-8 w-8 -mt-1"><use :xlink:href="`#free-trial`" /></svg>
            </div>

            <p class="mb-4">Trial information and next steps</p>

            <TrialBox />
          </div>
        </div>
      </div>
    </div>

    <AdminModal :active="!!video.id" :title="video.title" wide @close="videoId = null">
      <Vimeo :vid="video.id" class="-m-2 rounded-br-xl" />
    </AdminModal>
  </main>
</template>
