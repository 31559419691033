import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);

export default {
  install(Vue) {
    Vue.prototype.$dayjs = dayjs;
    Vue.prototype.$djMed = djMed;
    Vue.prototype.$djMedT = djMedT;
    Vue.prototype.$djFormat = djFormat;
  },
};

export const $dayjs = dayjs;
export const medium = 'D MMM YYYY';
export const mediumTime = 'DD MMM YYYY, HH:mm';

export const djFormat = (date, format = medium, modify) => {
  return date ? (modify ? modify(dayjs.utc(date)) : dayjs.utc(date)).format(format) : '';
};

export const djMed = (date, modify) => djFormat(date, medium, modify);
export const djMedT = (date, modify) => djFormat(date, mediumTime, modify);
