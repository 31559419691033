<script>
import MusicPlayer from '@/content/components/partials/MusicPlayer';
import Loader from '@/global/components/Loader';
import title from '@/scripts/learningTitles';
import routeInfo from '@/mixins/routeInfo';
import { lessonQueries } from '@/scripts/queries';
import { uriSnip } from '@/scripts/filters';
import { activityTypes, peActivityOverwrite } from '@/scripts/strings';
import { mapGetters } from 'vuex';

// prettier-ignore
const partReviewNumbers = {
  warmup: 1,
  story: 2, skill: 2, card: 2, movements: 2,
  game: 3, song: 3, application: 3, competition: 3,
  review: 4,
};

const expectedFunsLevel = ['yellow', 'yellow', 'green', 'red', 'red', 'blue', 'blue'];

export default {
  components: { Loader, MusicPlayer },
  data: () => ({ lessonData: {} }),
  mixins: [routeInfo],

  metaInfo() {
    return { title: title(this.$route.params) };
  },

  computed: {
    activities() {
      const activities = activityTypes.map(({ slug, label }, i) => {
        return (this.isPlay ? [this.lessonData.parts[i]] : this.lessonData[slug])?.map(data => {
          let part = {
            ...data,
            ...data.part?.[0],
            typeLabel: (peActivityOverwrite[this.lessonData.lessonType] || {})[i] || label,
          };

          if (part.type === 'funs') {
            part.equipment = part.cards.flatMap(card => card.equipment || []);
            part.funsLevel = expectedFunsLevel[this.$route.params.year];
          } else if (part.type === 'warmup') {
            part.warmUpSteps = [
              ...(part.warmUpSteps || []).map(step => ({ ...step, subtitle: step.subtitle?.toLowerCase() })),
              { subtitle: 'What happens next?', body: 'You decide what steps to take next.', end: true },
            ];
          } else if (part.type === 'game') {
            if (this.isGym) part.abilityText = part.abilities.find(a => a.slug === this.ability.slug)?.text;
          }

          part.reviewNumber = partReviewNumbers[part.type];

          // if (this.isPlay) return { ...part, ...this.lesson.parts.find(p => p.slug === this.part.slug) };

          return part;
        });
      });

      return {
        parts: activities.map(col => col[0]) || [],
        altParts: activities.map(col => col[1]) || [],
      };
    },

    equipment() {
      return this.activities.parts.flatMap(part => (part && part.equipment) || []);
    },

    skill() {
      const data = this.lessonData || {};
      const skill = data.skill?.[0] || {};
      const card = data.funsStation?.[0] || skill.easy?.[0] || {};
      const station = card.parent || {};
      const focus = (this.yearNum === 5 ? skill.context?.[0] : skill.parent) || {};

      return {
        category: station.category,
        title: focus.title ? `${focus.title} Skills` : station.title || skill.displayTitle || '',
        label: focus.title || skill.label || station.label,
        skillWhat: data.skillWhat || card.skillWhat,
        skillHow: data.skillHow || card.skillHow,
      };
    },

    ability() {
      const lesson = this.lessonData || {};
      let { abilityWhat, abilityHow, ...ability } = {
        ...(lesson.unit && this.chosenAbility(lesson.unit)),
        ...(this.isDance && this.chosenAbility(lesson)),
      };

      if (this.isPe) {
        const l = `l${this.$route.params.lesson}`;
        const unit = lesson.unit || { abilityWhat: [] };
        abilityWhat = lesson.abilityWhat || unit.abilityWhat.filter(row => row[l]) || [];
        abilityHow = lesson.abilityHow;
      } else if (this.isPlay) {
        abilityWhat = lesson.abilityWhat || [];
        ability.strapline = lesson.unit?.strapline;
      }

      const reviewQuestions = [...(lesson.reviewQuestions || []), ...(ability.reviewQuestions || [])].map(q =>
        typeof q === 'object' ? q.question : q
      );

      return { ...ability, abilityWhat, abilityHow, reviewQuestions };
    },

    keywords() {
      return [...(this.lessonData?.keywords || []), ...(this.ability?.keywords || [])];
    },

    lesson() {
      if (!this.lessonData?.id) return {};
      const { warmUp, review, ...lesson } = this.lessonData;
      return { ...lesson, ...this.activities, equipment: this.equipment, keywords: this.keywords };
    },

    ...mapGetters('user', ['chosenAbility']),
  },

  methods: {
    fetch() {
      const variables = { uri: uriSnip(this.$route.path, this.isPlay ? 7 : 8), funsLevel: this.funsLevel };
      this.$gql(lessonQueries[this.productSlug], variables, { required: ['lesson'] }).then(res => {
        if (this.lessonData) this.lessonData = res.lesson;
        if (!this.$route.params.part) this.$store.dispatch('user/last', res.lesson);
      });
    },
  },

  created() {
    this.fetch();
  },

  watch: { '$route.params.lesson': 'fetch' },
};
</script>

<template>
  <Loader :res="lesson.id || lesson.$resolved">
    <RouterView :lesson="lesson" :ability="ability" :skill="skill" />
    <MusicPlayer :playlist="lesson.playlist" v-if="hasMusicPlayer" />
  </Loader>
</template>
