<script>
import AdminSpinner from '@/admin/components/AdminSpinner';
import Pagination from '@/admin/components/Pagination';
import AdventureIcon from '@/bursts/components/AdventureIcon';
import Ribbon from '@/bursts/components/Ribbon';

import { $burstsEvents } from '@/scripts/models';

export default {
  components: { AdminSpinner, AdventureIcon, Ribbon, Pagination },
  props: ['groupId', 'studentId'],
  data: () => ({ events$: $burstsEvents.all(), events: [] }),

  computed: {
    mapped() {
      return this.events.map(event => ({
        ...event,
        ...event.extra,
        date: this.$djMed(event.dateCreated),
      }));
    },
  },

  methods: {
    nav(link) {
      this.fetch(link.page);
    },

    async fetch(page) {
      this.events = await this.events$.$get({
        ...(this.groupId && { groupId: this.groupId }),
        ...(this.studentId && { studentId: this.studentId }),
        page,
      });
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>

<template>
  <AdminSpinner v-if="!events$.empty" :res="events$" hide>
    <div class="mt-8">
      <div class="flex items-center mb-2">
        <h2 class="head-2xl capitalize text-theme">Latest</h2>
      </div>

      <div class="flex flex-wrap -mx-3">
        <div v-for="(event, i) in mapped" :key="i" class="w-1/2 xl:w-1/3 px-3 my-1">
          <div class="rounded-lg bg-white flex items-center justify-between px-3 py-1">
            <div class="text-bursts font-bold text-lg truncate">{{ event.name }}</div>
            <div class="latest-detail flex justify-between items-center shrink-0 ml-1">
              {{ event.date }}
              <Ribbon v-if="event.event === 'level'" :level="event.level" class="text-xs" />
              <AdventureIcon
                v-else-if="event.event === 'activity'"
                :id="event.adventureId"
                :level="event.level"
                small
              />
            </div>
          </div>
        </div>
      </div>

      <Pagination :collection="events$" @change="nav" class="mt-3" />
    </div>
  </AdminSpinner>
</template>

<style lang="postcss">
.latest-detail {
  width: 160px;
  min-height: 40px;
}
</style>
