<script>
import '@/assets/svgs/buttons/home-challenge.svg';
import '@/assets/svgs/buttons/review-cloud.svg';
import '@/assets/svgs/buttons/more-active.svg';
import '@/assets/svgs/buttons/think-about.svg';
import '@/assets/svgs/buttons/equipment.svg';
import '@/assets/svgs/buttons/success.svg';
import '@/assets/svgs/buttons/trickiest.svg';
import '@/assets/svgs/buttons/trickier.svg';
import '@/assets/svgs/buttons/tricky.svg';
import '@/assets/svgs/cog.svg';

export default {
  props: {
    icon: { type: String, default: '' },
    colour: { type: String, default: 'white' },
    highlight: Boolean,
  },

  computed: {
    bg() {
      return this.highlight ? 'white' : this.colour;
    },
    fg() {
      return this.colour === 'white' || this.highlight ? '' : 'white';
    },
    fill() {
      return this.highlight || this.icon === 'cog' ? this.colour : `${this.colour}-shade`;
    },
  },
};
</script>

<template>
  <div class="pb-2 t-col">
    <div :class="[`bg-${bg} text-${fg} border-${colour || 'clear'}`, { highlight }]" class="txt-block relative">
      <div v-if="icon" class="absolute top-0 right-0 m-3">
        <svg :class="`fill-${fill}`" class="w-12 h-12 print:h-6 print:w-6"><use :xlink:href="`#${icon}`" /></svg>
      </div>

      <slot />
    </div>
  </div>
</template>
