<script>
import CogModal from '@/content/components/modal/CogModal';
import ReviewModal from '@/content/components/modal/ReviewModal';
import PhysicalModal from '@/content/components/modal/PhysicalModal';
import AudioPlayer from '@/content/components/partials/AudioPlayer';
import Song from '@/content/components/activities/Song';
import Hero from '@/content/components/partials/Hero';
import VideoButton from '@/content/components/buttons/VideoButton';
import DiceModal from '@/content/components/modal/DiceModal';
import WarmupPupil from '@/content/components/activities/WarmupPupil';
import VimeoText from '@/content/components/partials/VimeoText';
import DifficultySelect from '@/content/components/partials/DifficultySelect';
import TextBlock from '@/content/components/partials/TextBlock';
import PartSteps from '@/content/components/activities/PartSteps';
import routeInfo from '@/mixins/routeInfo';

export default {
  // prettier-ignore
  components: {
    CogModal, ReviewModal, PhysicalModal, VideoButton, AudioPlayer, DiceModal,
    Hero, Song, WarmupPupil, VimeoText, DifficultySelect, TextBlock, PartSteps
  },

  props: ['lesson', 'ability', 'skill', 'part'],
  data: () => ({ seconds: 0, showVideo: false, level: 'tricky', step: 0 }),
  mixins: [routeInfo],

  computed: {
    title() {
      return this.part.displayTitle || this.part.title.replace(/ - [0-9]+$/, '');
    },
    showPhysical() {
      return this.part.type !== 'review' && this.part.type !== 'warmup';
    },
    isSong() {
      return this.part.type === 'song';
    },
    showCog() {
      return this.part.type !== 'movements';
    },
    levelText() {
      return this.part[this.level];
    },
    cogText() {
      if (this.isGym && this.ability) {
        const text = this.part.abilityText;
        return text ? `<h2 class="text-4xl">${this.ability.title}</h2>${text}` : '';
      }
      return this.part.cogOverwrite || '';
    },
  },

  methods: {
    stepUpdate(step) {
      this.$emit('step', step);
      this.step = step;
    },
  },
};
</script>

<template>
  <div class="bg-white curves parts-offset">
    <div class="box-contain">
      <div class="box-title">
        <h1>{{ title }}</h1>
        <div class="text-2xl font-medium" v-if="part.tune">To the tune of: {{ part.tune }}</div>
      </div>

      <div class="box-buttons">
        <VideoButton v-if="part.video && !isSong" @click="showVideo = !showVideo" />
        <DiceModal :dice="part.dice" :part="part" :ability="ability" />
        <ReviewModal :ability="ability" :number="part.reviewNumber" />
        <AudioPlayer :audio="part.audioMusic" @tick="seconds = $event" progress loop />
        <PhysicalModal v-if="showPhysical" :skill="skill" :entry="part" :text="part.physicalOverwrite" />
        <CogModal v-if="showCog" :lesson="lesson" :ability="ability" :text="cogText" />
      </div>
    </div>

    <template v-if="showVideo">
      <VimeoText :vid="part.video" :key="part.video" :lines="part.textLines" colour="pe" autoplay class="mb-5" />
    </template>
    <template v-else>
      <Hero :asset="part.heroImage" class="mb-5 hero-max" />
      <PartSteps :part="part" :key="part.id" @change="stepUpdate" />
    </template>

    <div v-if="part.pupilText" class="mx-8 my-5">
      <div v-html="part.pupilText" class="rdct rdct-pupil"></div>
    </div>

    <WarmupPupil v-if="part.warmUpSteps" :partDice="part.dice" :steps="part.warmUpSteps" />
    <Song v-else-if="isSong" :part="part" :seconds="seconds" />

    <div v-if="part.tricky" class="mx-8">
      <TextBlock :icon="level" colour="tricky" highlight class="mb-10">
        <h2 class="teacher-title capitalize text-tricky">{{ level }}</h2>
        <div v-html="levelText" class="rdct rdct-pupil"></div>
      </TextBlock>
      <DifficultySelect @change="level = $event" />
    </div>
  </div>
</template>

<style lang="postcss">
.hero-max {
  max-height: 560px;
}
</style>
