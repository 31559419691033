<script>
import Ribbon from '@/bursts/components/Ribbon';
import AdventureIcons from '@/bursts/components/AdventureIcons';
import ActiveMins from '@/bursts/components/ActiveMins';
import { mapGetters } from 'vuex';

export default {
  components: { Ribbon, AdventureIcons, ActiveMins },
  props: ['student', 'groupId'],
  computed: {
    active() {
      return this.student.status > 0;
    },
    ...mapGetters('account', ['burstsUri']),
  },
};
</script>

<template>
  <RouterLink
    :to="`${burstsUri}groups/${groupId}/students/${student.id}`"
    :class="active ? 'text-bursts' : 'text-bursts-shade'"
    class="flex-grow card p-0 pl-0.5 overflow-hidden outlne"
  >
    <div class="flex items-center justify-between p-2">
      <div class="text-xl">
        <div class="font-semibold text-bursts">{{ student.firstName }} {{ student.lastName }}</div>
        <ActiveMins v-if="active" :class="{ 'opacity-25': !active }" :record="student" />
      </div>
      <Ribbon v-if="active" :class="{ 'opacity-25': !active }" :level="student.level" />
    </div>

    <div v-if="active" :class="{ 'opacity-25': !active }">
      <AdventureIcons :student="student" class="border-t border-grey-l2" small />
      <div class="flex text-centexr justify-betwxeen text-sm leading-none text-black border-t border-grey-l2">
        <div class="px-2 py-1 flex items-center w-1/4 border-r border-grey-l2" style="backgroxund: #fcf1d4">
          <img src="/images/bursts/brave-icon.svg" alt="" class="h-6 mr-1 -ml-0.5" />
          {{ student.brave }}
        </div>
        <div class="px-2 py-1 flex items-center w-1/4 border-r border-grey-l2" style="backgxround: #d3e5d3">
          <img src="/images/bursts/wise-icon.svg" alt="" class="h-6 mr-1 -ml-0.5" />
          {{ student.wise }}
        </div>
        <div class="px-2 py-1 flex items-center w-1/4 border-r border-grey-l2" style="backgrxound: #ead4e3">
          <img src="/images/bursts/helpful-icon.svg" alt="" class="h-6 mr-1 -ml-0.5" />
          {{ student.helpful }}
        </div>
        <div class="px-2 py-1 flex items-center w-1/4" style="backgxround: #d1e2f2">
          <img src="/images/bursts/imaginative-icon.svg" alt="" class="h-6 mr-1 -ml-0.5" />
          {{ student.imaginative }}
        </div>
      </div>
    </div>
  </RouterLink>
</template>
