<script>
import Hero from '@/content/components/partials/Hero';
import CogModal from '@/content/components/modal/CogModal';
import VideoButton from '@/content/components/buttons/VideoButton';
import ReviewModal from '@/content/components/modal/ReviewModal';
import DiceModal from '@/content/components/modal/DiceModal';
import WarmupPupil from '@/content/components/activities/WarmupPupil';
import TextBlock from '@/content/components/partials/TextBlock';
import DifficultySelect from '@/content/components/partials/DifficultySelect';
import PhysicalModal from '@/content/components/modal/PhysicalModal';
import DanceSkill from '@/content/components/activities/DanceSkill';

export default {
  // prettier-ignore
  components: {
    CogModal, DiceModal, ReviewModal, PhysicalModal, VideoButton, 
    Hero, WarmupPupil, TextBlock, DifficultySelect, DanceSkill, 
  },

  props: ['lesson', 'ability', 'skill', 'part'],
  data: () => ({ skillLevel: 'tricky' }),
};
</script>

<template>
  <div class="bg-white curves parts-offset">
    <div class="box-contain">
      <h1 class="box-title text-dance">{{ part.displayTitle || part.title }}</h1>

      <div class="box-buttons">
        <VideoButton v-if="part.video && $route.params.type !== 'skill'" @click="showVideo = !showVideo" />
        <PhysicalModal :skill="skill" v-if="$route.params.type === 'skill'" />
        <DiceModal :dice="part.dice" :part="part" :ability="ability" />
        <ReviewModal :ability="ability" :number="part.reviewNumber" />
        <CogModal
          :text="$route.params.type === 'application' ? ability.appPoints : ''"
          :lesson="lesson"
          :ability="ability"
        />
      </div>
    </div>

    <Hero :asset="part.heroImage" v-if="part.type !== 'skill'" />

    <WarmupPupil v-if="part.type === 'warmup'" :steps="part.warmUpSteps" />

    <DanceSkill v-else-if="part.type === 'skill'" :lesson="lesson" :part="part" />

    <div v-else-if="part.type === 'review'" v-html="part.pupilText" class="px-12 py-8 rdct rdct-pupil" />

    <template v-else-if="part.type === 'application' || part.type === 'performance'">
      <div v-if="part.pupilText" class="px-12 py-8 rdct rdct-pupil">
        <h2>How to create your dance</h2>
        <div v-html="part.pupilText"></div>
      </div>

      <TextBlock :icon="skillLevel" colour="tricky" highlight class="px-16 mb-16 rdct rdct-pupil">
        <h2 class="teacher-title capitalize text-tricky">{{ skillLevel }}</h2>
        <div v-html="part[skillLevel]" />
      </TextBlock>

      <DifficultySelect :dice="part.dice" @change="skillLevel = $event" />
    </template>
  </div>
</template>
