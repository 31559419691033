<script>
import TrialModal from '@/global/components/TrialModal';
import { mapGetters } from 'vuex';

export default {
  components: { TrialModal },
  data: () => ({ showTrial: 0 }),

  computed: {
    ...mapGetters('user', ['isAdmin']),
    ...mapGetters('account', ['trialExpRealPe', 'trialDaysRealPe']),
    ...mapGetters(['hasRealPeTrial', 'hasRealPeFull']),
  },
};
</script>

<template>
  <div v-if="!hasRealPeFull && trialExpRealPe">
    <slot />
    <template v-if="hasRealPeTrial">
      <strong>{{ trialDaysRealPe }} days remaining </strong> - {{ trialExpRealPe }}
    </template>
    <div v-else class="font-bold">Your trial has ended</div>

    <div v-if="isAdmin && hasRealPeTrial" class="flex flex-wrap -m-1 mt-4">
      <button class="db1" @click="showTrial = 1">Purchase</button>
      <button class="db1" @click="showTrial = 2">End trial</button>
    </div>

    <TrialModal :active="showTrial" @close="showTrial = 0" />
  </div>
</template>
