<script>
import AdminSpinner from '@/admin/components/AdminSpinner';
import ExportButton from '@/admin/components/ExportButton';
import FormControl from '@/admin/components/FormControl';

const base = { month: '' };

export default {
  components: { AdminSpinner, ExportButton, FormControl },
  data: () => ({ accounts: [], ready: false, ready2: false, filters: [], ...base }),

  computed: {
    params() {
      return {
        ...(this.month && { date: this.month }),
      };
    },
  },

  methods: {
    reset() {
      Object.keys(base).forEach(key => {
        this[key] = base[key];
      });

      this.fetch();
    },

    fetch() {
      this.ready = false;
      this.$api.get('/bursts/accounts/table', { params: this.params }).then(res => {
        this.ready = true;
        this.accounts = res.data;
      });
    },
  },

  mounted() {
    this.fetch();
    this.$api.get('bursts/accounts/filters').then(res => {
      this.ready2 = true;
      this.filters = res.data;
    });
  },
};
</script>

<template>
  <div class="container">
    <div class="flex justify-between items-center mb-6">
      <div class="head-4xl text-theme">Account Activity</div>
      <div class="btns4">
        <ExportButton label="Accounts" path="bursts/accounts" :params="params" />
      </div>
    </div>

    <AdminSpinner :ready="ready2">
      <div class="p-3 pt-2 pb-1 mb-10 bg-white rounded-lg shadow my-5">
        <div class="flex flex-wrap -mx-1 items-center">
          <div class="px-1 w-1/3">
            <FormControl id="month" label="Month" theme="ring" :active="month">
              <select id="month" v-model="month">
                <option value="">-</option>
                <option v-for="month in filters.months" :key="month.key" :value="month.val">
                  {{ month.label }}
                </option>
              </select>
            </FormControl>
          </div>

          <div class="btns4 p-2 py-3 flex-grow">
            <button class="btn4-clear" @click="reset">Reset</button>
            <button class="btn4 bg-bursts" @click="fetch">Filter</button>
          </div>
        </div>
      </div>
    </AdminSpinner>

    <AdminSpinner :ready="ready" class="loader-lg" hide>
      <div class="container">
        <table class="my-6 mx-auto">
          <tr class="lbl4 text-center border-b-2 border-grey-l1">
            <td class="ytd-r">Account Name</td>
            <td class="ytd-r bg-white">Groups</td>
            <td class="ytd-r">Students</td>
            <td class="ytd-r bg-white">
              Active
              <br />
              Students
            </td>
            <td class="ytd-r">Minutes</td>
            <td class="ytd-r bg-white">/ Student</td>
            <td class="ytd-r">
              / Active
              <br />
              Student
            </td>
          </tr>
          <tr v-for="account in accounts" :key="account.id" class="border-t border-grey-l1 text-center">
            <td class="ytd-r text-left">{{ account.name }}</td>
            <td class="ytd-r bg-white">{{ account.groups }}</td>
            <td class="ytd-r">{{ account.students }}</td>
            <td class="ytd-r bg-white">{{ account.activeStudents }}</td>
            <td class="ytd-r">{{ account.minutes }}</td>
            <td class="ytd-r bg-white">{{ account.minPerStudent }}</td>
            <td class="ytd-r">{{ account.minPerActiveStudent }}</td>
          </tr>
        </table>
      </div>
    </AdminSpinner>
  </div>
</template>
