<script>
export default {
  props: ['level'],
};
</script>

<template>
  <div>
    <div class="ribbon bg-no-repeat bg-contain text-center font-bold">
      {{ level }}
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.ribbon {
  background-image: url('@/assets/images/ribbon.svg');
  width: 2.5em;
  height: 3.125em;
  padding-top: 0.6em;
  color: #7e6300;
}
</style>
