<script>
import FormControl from '@/admin/components/FormControl';
import AdminSpinner from '@/admin/components/AdminSpinner';
import Datepicker from 'vuejs-datepicker';
import AdminModal from '@/admin/components/AdminModal';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { $licence } from '@/scripts/models';
import { mapGetters } from 'vuex';

const validDates = (end, vm) => vm.startDate < end;
const anyOne = val => val.length;

const products = ['pe', 'gym', 'dance', 'foundations', 'play', 'home'];
const years = ['EYFS', 'Y1', 'Y2', 'Y3', 'Y4', 'Y5/6', 'Y6'];

export default {
  components: { FormControl, AdminSpinner, Datepicker, AdminModal },
  mixins: [validationMixin],
  props: ['value', 'types'],
  data: () => ({ licence: $licence.one(), showDelete: false, current1: null, current2: null, years }),

  computed: {
    types1() {
      if (this.isPartner) return this.types.filter(type => type.title === 'Partner');
      return this.types.filter(type => type.title !== 'Partner');
    },
    types2() {
      return this.current1 && this.current1.subTypes.length > 1 ? this.current1.subTypes : [];
    },

    customYears() {
      return this.current2 && this.current2.customYears;
    },
    customProducts() {
      return this.current2 && this.current2.customProducts;
    },

    ...mapGetters('account', ['isPartner']),

    products() {
      return products.map(slug => ({
        slug,
        active: this.licence.products.includes(slug),
        disabled: slug === 'pe' && this.current2.forcePe,
      }));
    },

    isTrialReal() {
      return this.current2 && this.current2.title === 'Trial';
    },
    isBursts() {
      return this.current2 && this.current2.title === 'BURSTS';
    },

    endLabel() {
      return `${this.licence.renew ? 'Renew' : 'End'} Date`;
    },

    flatTypes() {
      return this.types1.flatMap(type1 => type1.subTypes);
    },

    renewAs() {
      return this.flatTypes.find(type => type.id === this.current2?.renewTypeId) || {};
    },
  },

  validations() {
    return {
      licence: {
        ...(this.customProducts && { products: { anyOne } }),
        ...(this.customYears && { years: { anyOne } }),
        startDate: { required },
        endDate: { required, validDates },
      },
    };
  },

  methods: {
    submit() {
      this.$v.$touch();

      if (this.licence.$ready && !this.$v.$invalid) {
        if (!this.customProducts) this.licence.products = [];
        if (!this.customYears) this.licence.years = [];

        if (this.isBursts) {
          this.licence.renew = 0;
          this.licence.status = '';
        }

        this.licence.licenceType = { id: this.current2.id };
        this.licence.id ? this.updateLicence() : this.createLicence();
      }
    },

    createLicence() {
      this.licence.accountId = this.$route.params.accountId;
      this.licence.$create().then(() => {
        this.$emit('close', 1);
      });
    },

    updateLicence() {
      this.licence.$update().then(() => {
        this.$emit('close', 1);
      });
    },

    deleteLicence() {
      if (this.licence.$ready) {
        this.licence.$delete().then(() => {
          this.$emit('close', 1);
        });
      }
    },

    setDate(val, field) {
      if (field === 'startDate' && !this.licence.id && !this.$v.licence.endDate.$dirty && this.current2.id) {
        this.$v.licence.endDate.$model = this.$dayjs(val)
          .add(...this.current2.duration.split(' '))
          .format('YYYY-MM-DD');
      }

      this.$v.licence[field].$touch();
      this.$v.licence[field].$model = this.$dayjs(val).format('YYYY-MM-DD');
    },

    changeType1() {
      const match = this.current1.subTypes.find(type2 => type2.subtitle === this.current2.subtitle);
      this.current2 = match || this.current1.subTypes[0];
      if (this.customProducts) this.licence.products = this.current2.products;
    },
  },

  mounted() {
    if (this.value.id) {
      this.licence.$load(this.value);

      const type1 = this.value.licenceType.title;
      const type2 = this.value.licenceType.subtitle;

      this.current1 = this.types1.find(type => type1 === type.title) || this.types1[0];
      this.current2 = this.types2.find(type => type2 === type.subtitle) || this.current1.subTypes[0];
    } else {
      this.current1 = this.types1[0];
      this.current2 = this.current1.subTypes[0];

      this.licence.status = this.isPartner ? 'free' : 'new';
    }
  },
};
</script>

<template>
  <div>
    <AdminSpinner :res="licence">
      <form @submit.prevent="submit()" class="relative">
        <FormControl slug="current1" label="Membership">
          <select id="current1" v-model="current1" @change="changeType1">
            <option v-for="type1 in types1" :key="type1.title" :value="type1">
              {{ type1.title }}
            </option>
          </select>
        </FormControl>

        <FormControl v-if="types2.length" :label="isBursts ? 'Licence Type' : 'Year Group'" slug="current2">
          <select id="current2" v-model="current2">
            <option v-for="type2 in types2" :key="type2.id" :value="type2">{{ type2.subtitle }}</option>
          </select>
        </FormControl>

        <FormControl v-if="customYears" :v="$v.licence" slug="products" label="Programmes">
          <div class="flex flex-wrap -m-1">
            <label
              v-for="product in products"
              :key="product.slug"
              :class="{ active: product.active, 'view-only': product.disabled }"
              class="year-select"
            >
              <input type="checkbox" v-model="licence.products" :value="product.slug" :disabled="product.disabled" />
              {{ product.slug }}
            </label>
          </div>
        </FormControl>

        <FormControl v-if="customYears" :v="$v.licence" slug="years" label="Year access">
          <div class="flex flex-wrap -m-1">
            <label
              v-for="(year, i) in years"
              :key="year"
              :class="{ active: licence.years.includes(i) }"
              class="year-select"
            >
              <input type="checkbox" v-model="licence.years" :value="i" />
              {{ year }}
            </label>
          </div>
        </FormControl>

        <FormControl v-if="!isBursts && !isTrialReal" slug="status" label="Licence Type">
          <select id="status" v-model="licence.status" class="capitalize">
            <option>new</option>
            <option>renewal</option>
            <option>upgrade</option>
            <option>free</option>
          </select>
        </FormControl>

        <div class="flex -mx-3">
          <FormControl :v="$v.licence" slug="startDate" label="Start Date" class="w-1/2 px-3">
            <Datepicker :value="licence.startDate" id="startDate" @input="setDate($event, 'startDate')" />
          </FormControl>

          <FormControl :v="$v.licence" :label="endLabel" slug="endDate" class="w-1/2 px-3">
            <Datepicker :value="licence.endDate" id="endDate" @input="setDate($event, 'endDate')" />
          </FormControl>
        </div>

        <template v-if="!isBursts">
          <hr />
          <div class="flex -mx-2">
            <div class="w-1/2 px-2">
              <label class="option-desc">
                <input type="checkbox" v-model="licence.renew" />
                <div>
                  Renew
                  <div class="desc" v-if="licence.renew">Licence will renew automatically</div>
                  <div class="desc" v-else>Licence will not renew</div>
                </div>
              </label>
            </div>
            <div class="w-1/2 px-2">
              <div class="option-desc">Renews as</div>
              <div class="text-sm">
                <span class="text-pe font-bold">{{ renewAs.title }}</span> {{ renewAs.subtitle }}
              </div>
            </div>
          </div>
        </template>

        <hr />

        <FormControl slug="comments" label="Comments">
          <textarea v-model.trim="licence.comments" rows="3" id="comments"></textarea>
        </FormControl>

        <div class="btns4">
          <button v-if="licence.id" class="btn4" type="button" @click="showDelete = true">Delete</button>
          <div class="flex-grow"></div>
          <button class="btn4-clear" type="button" @click="$emit('close')">Cancel</button>
          <button class="btn4 bg-theme" type="submit">Save</button>
        </div>
      </form>
    </AdminSpinner>

    <AdminModal title="Delete Licence?" inline :active="showDelete" @close="showDelete = false">
      <div class="btns4">
        <button @click="showDelete = false" class="btn4-clear">Cancel</button>
        <button @click="deleteLicence" class="btn4 bg-fun-red">Delete</button>
      </div>
    </AdminModal>
  </div>
</template>
