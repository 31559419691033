<script>
import LessonPreview from '@/content/components/previews/LessonPreview';
import SharedPartPupil from '@/content/components/parts/SharedPartPupil';
import PlayPartPupil from '@/content/components/parts/PlayPartPupil';
import DancePartPupil from '@/content/components/parts/DancePartPupil';
import ActivityPlanning from '@/content/components/parts/ActivityPlanning';
import PageTitle from '@/content/components/partials/PageTitle';

import PeSkill from '@/content/components/activities/PeSkill';
import GymSkill from '@/content/components/activities/GymSkill';

import Loader from '@/global/components/Loader';

import AbilitySelectModal from '@/content/components/modal/AbilitySelectModal';
import OrganisationModal from '@/content/components/modal/OrganisationModal';
import CogModal from '@/content/components/modal/CogModal';
import AssessmentButton from '@/content/components/buttons/AssessmentButton';
import DocButton from '@/content/components/buttons/DocButton';
import PrintButton from '@/content/components/buttons/PrintButton';

import MusicPlayerToggle from '@/content/components/buttons/MusicPlayerToggle';
import AlsoButton from '@/content/components/buttons/AlsoButton';

import HomeChallengeModal from '@/content/components/modal/HomeChallengeModal';
import MoreActiveModal from '@/content/components/modal/MoreActiveModal';
import JoiningInModal from '@/content/components/modal/JoiningInModal';
import PhysicalModal from '@/content/components/modal/PhysicalModal';
import ReviewModal from '@/content/components/modal/ReviewModal';

import gymSkill from '@/queries/gym/skill.gql';
import { mapState, mapGetters } from 'vuex';
import routeInfo from '@/mixins/routeInfo';

const types = {
  pe: { funs: 'PeSkill' },
  gym: { card: 'GymSkill' },
  play: { pupil: 'Play' },
  dance: { pupil: 'Dance' },
};

export default {
  // prettier-ignore
  components: {
    PageTitle, Loader, LessonPreview, PeSkill, GymSkill, 
    SharedPartPupil, PlayPartPupil, DancePartPupil, 
    ActivityPlanning, 
    AbilitySelectModal, OrganisationModal, CogModal, HomeChallengeModal, MoreActiveModal, 
    JoiningInModal, PhysicalModal, ReviewModal, AssessmentButton, 
    PrintButton, AlsoButton, DocButton, MusicPlayerToggle, 
  },

  mixins: [routeInfo],
  props: ['lesson', 'ability', 'skill'],
  data: () => ({ part: { $resolved: false } }),

  metaInfo() {
    return { title: this.part?.title || '' };
  },

  computed: {
    pupilType() {
      const product = types[this.productSlug] || {};
      return product[this.part.type] || `${product.pupil || 'Shared'}PartPupil`;
    },

    title() {
      return this.crumbsTitle.slice(this.isPlay ? 2 : 1).join(', ');
    },

    ...mapState(['view']),
    ...mapGetters('menu', ['crumbsTitle']),
  },

  methods: {
    async fetch() {
      const { part, type } = this.$route.params;
      this.part = [...this.lesson.parts, ...this.lesson.altParts].find(p => p && p.slug === part);

      if (this.isGym && !this.part && type === 'card') {
        await this.$gql(gymSkill, { part }).then(res => {
          this.part = res.part;
        });
      }
      if (this.part) this.$store.dispatch('user/last', this.part);
    },
  },

  created() {
    this.fetch();
  },

  watch: { $route: 'fetch' },
};
</script>

<template>
  <Loader :res="part?.id" class="container">
    <PageTitle :title="title" :lesson="lesson" :ability="ability" toggle>
      <AlsoButton :lesson="lesson" v-if="!view" />

      <template v-if="view">
        <!-- <AssessmentButton /> -->
        <DocButton :entry="part" />
        <PrintButton />
        <!-- <CogModal :lesson="lesson" :ability="ability" /> -->
      </template>
      <template v-else>
        <MusicPlayerToggle v-if="hasMusicPlayer" />
        <!-- <MoreActiveModal :part="part" /> -->
        <JoiningInModal :ability="ability" :part="part" />
        <OrganisationModal :entry="part" />
        <HomeChallengeModal :lesson="lesson" v-if="part.type === 'review'" />
      </template>
    </PageTitle>
    <AbilitySelectModal v-if="productInfo.abilitySelect" :lesson="lesson" modal-only />

    <ActivityPlanning :lesson="lesson" :ability="ability" :skill="skill" :part="part" v-if="view" />
    <template v-else>
      <component :is="pupilType" :lesson="lesson" :ability="ability" :skill="skill" :part="part" />
      <LessonPreview :lesson="lesson" pupil class="mx-2 mt-5 lg:mx-4 xl:mx-20 border-6 border-grey-l3" />
    </template>
  </Loader>
</template>
