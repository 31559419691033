<script>
import AdventureIcon from '@/bursts/components/AdventureIcon';

const adventures = [2, 4, 3, 1];

export default {
  components: { AdventureIcon },
  props: { student: {}, small: Boolean },

  computed: {
    adventures() {
      const levels = this.student.adventureLevels || {};
      return adventures.map(id => ({ id, level: levels[id] || 0 }));
    },
  },
};
</script>

<template>
  <div class="flex text-white justify-betwxeen text-sm leading-none">
    <div v-for="item in adventures" :key="item.id" :class="small ? 'split-r-l2' : ''" class="px-2 py-1 w-1/4">
      <AdventureIcon :id="item.id" :level="item.level" :small="small" />
    </div>
  </div>
</template>
