export const address = account => {
  const address = { ...account.address, county: account.county ? account.county.title : '' };

  return ['line1', 'line2', 'city', 'county', 'postcode']
    .map(line => address[line])
    .filter(l => l)
    .join(', ');
};

export const num = val => ((val || 0) + '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const uriSnip = (val, length) => val.split('/').slice(0, length).join('/');

export const zip = (a, b) => {
  if (!a || !b) return [];
  if (a.length < b.length) return zip(b, a);
  return a.map((k, i) => ({ ...k, ...(b[i] || {}) }));
};

export const undupe = (array, key = 'title') => [...new Set((array || []).map(item => item[key]?.trim() || item))];
