import axios from 'axios';
import router from '@/router';
import store from '@/store';
import { partials } from '@/scripts/queries';

const fixUrl = ([key, value]) => [key, key === 'uri' ? `/${value}` : loopData(value)];

const loopData = data => {
  if (Array.isArray(data)) return data.map(loopData);
  if (typeof data === 'object' && data !== null) return Object.fromEntries(Object.entries(data).map(fixUrl));
  return data;
};

const api = axios.create({
  baseURL: `${process.env.VUE_APP_CMS}gql`,
});

const config = { headers: {} };

function gql(query, variables = {}, options = {}) {
  const include = options.include || options.inc || {};
  const required = options.required || [];

  if (variables.uri) {
    variables.uri = variables.uri.replace(/^\//, '');
  }

  query = query.replace(/cinc_([a-zA-z]+)/, (match, slug) => {
    return partials[slug] && include[slug] ? partials[slug].replace(/^{|}$/g, '') : '';
  });
  query = query.replace(/inc_([a-zA-z]+)/, (match, slug) => {
    return partials[slug] ? partials[slug].replace(/^{|}$/g, '') : '';
  });

  const product = router.currentRoute.params.product;

  // config.headers.Authorization = `Bearer ${store.state.user.token}`;
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

  return api
    .post(options.schema || product || 'global', { query, variables }, config)
    .then(res => {
      const { data, errors } = res.data;

      if (errors && errors.length) {
        errors.forEach(error => {
          throw error;
        });
      }

      required.forEach(key => {
        if (data[key] === null) {
          console.error('gql response missing required data -', key);
          store.commit('toast/error', 'Page not found');
          router.push('/');
        }
      });

      return loopData(data);
    })
    .catch(error => {
      if (error.response && error.response.status === 403) {
        store.commit('toast/error', error);
        router.push('/');
      }

      throw error;
    });
}

export default gql;
