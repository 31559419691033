<script>
export default {
  props: { record: {}, full: Boolean },

  computed: {
    change() {
      return this.record.totalMinutes - this.record.previousMinutes;
    },
  },
};
</script>

<template>
  <div class="flex items-center text-bursts leading-none">
    <img src="/images/bursts/minutes-icon.svg" alt="" class="widthr-1 mr-2" />
    <div class="font-semibold">
      {{ record.totalMinutes }}
      <span v-if="full" class="textr-02">mins</span>
    </div>
    <div v-if="change" class="text text-fun-green pl-2">
      <span class="textr-08">↑</span>
      {{ change }}
    </div>
  </div>
</template>
