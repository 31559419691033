<script>
import StudentReinvite from '@/bursts/components/StudentReinvite';
import AdminSpinner from '@/admin/components/AdminSpinner';
import StudentList from '@/bursts/components/StudentList';
import UserList from '@/admin/components/users/UserList';
import AdminModal from '@/admin/components/AdminModal';
import GroupForm from '@/bursts/components/GroupForm';
import Ribbons from '@/bursts/components/Ribbons';
import Events from '@/bursts/components/Events';
import { $group } from '@/scripts/models';
import { mapGetters } from 'vuex';
import '@/assets/svgs/icons/cross.svg';

export default {
  components: { AdminSpinner, AdminModal, StudentList, GroupForm, UserList, Events, Ribbons, StudentReinvite },
  data: () => ({ group: $group.one(), showEdit: false, showInvite: false }),

  metaInfo() {
    return { title: this.group.name || 'Group' };
  },

  computed: {
    groupId() {
      return this.$route.params.groupId;
    },
    userParams() {
      return { groupId: this.groupId, accountId: this.accountId };
    },
    showTeachers() {
      return this.isCreate || this.isAdmin;
    },
    showReinvite() {
      return this.isAdmin && this.group.totalInactiveStudents;
    },

    ...mapGetters('user', ['isCreate', 'isAdmin', 'isCdAdmin']),
    ...mapGetters('account', ['accountId']),
  },

  methods: {
    fetch() {
      this.group.$get({ id: this.groupId });
    },

    sync() {
      const schoolId = this.$store.state.account.wondeId;
      this.$api.post(`wonde/${schoolId}/groups/${this.group.wondeId}`).then(res => {
        this.$store.commit('toast/info', res);
        this.fetch();
      });
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>

<template>
  <div class="container">
    <AdminSpinner :res="group" hide>
      <div class="title-flex">
        <h2 class="head-4xl text-theme">{{ group.name }}</h2>

        <div class="btns4">
          <button v-if="isCdAdmin" class="btn4-clear" @click="sync">Sync Class</button>
          <button v-if="showReinvite" class="btn4" @click="showInvite = true">Re-Invite Children</button>
          <button v-if="isAdmin" class="btn4 bg-theme" @click="showEdit = true">Edit</button>
        </div>
      </div>
      <p>{{ group.subject }} {{ group.description }}</p>
      <hr />

      <Ribbons :record="group" />
      <hr />
    </AdminSpinner>

    <Events :groupId="groupId" />

    <UserList v-if="showTeachers" :params="userParams" title="Teachers" class="my-8" simple>
      There are currently no teachers connected to this class
    </UserList>

    <StudentList :groupId="groupId" label="active" :status="[2, 1]" class="my-8" />
    <StudentList :groupId="groupId" label="inactive" :status="0" class="my-8" />

    <AdminModal :active="showEdit" title="Edit Class" @close="showEdit = false">
      <GroupForm v-model="group" @close="showEdit = false" />
    </AdminModal>

    <StudentReinvite v-if="isAdmin" :group="group" :active="showInvite" @change="fetch()" @close="showInvite = false" />
  </div>
</template>
