const activityTypes = [
  { slug: 'warmUp', label: 'Warm-Up' },
  { slug: 'skill', label: 'Skill' },
  { slug: 'application', label: 'Skill Application' },
  { slug: 'review', label: 'Review Method' },
];
const peActivityOverwrite = {
  personalBest: { 1: 'PB Challenge', 2: 'Skill' },
  personalBest2: { 2: 'PB Challenge' },
  repeatGame: { 1: 'Game', 2: 'Skill' },
  competition: { 1: 'Game', 2: 'Competition' },
};

const abilityStrings = {
  personal: 'Personal',
  cognitive: 'Cognitive',
  social: 'Social',
  fitness: 'Health and Fitness',
  creative: 'Creative',
  physical: 'Physical',
};

const status = { 0: 'Prospective', 10: 'Inactive', 30: 'Active' };

const trickyLevels = ['tricky', 'trickier', 'trickiest'];

export { activityTypes, peActivityOverwrite, status, abilityStrings, trickyLevels };
