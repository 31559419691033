<script>
import Ribbon from '@/bursts/components/Ribbon';
import ActiveMins from '@/bursts/components/ActiveMins';
import ActiveUsers from '@/bursts/components/ActiveUsers';

export default {
  components: { Ribbon, ActiveMins, ActiveUsers },
  props: ['record'],

  computed: {
    ribbons() {
      return (this.record.levels || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
        .map((total, i) => ({ level: i, total }))
        .filter(ribbon => ribbon.level && ribbon.total);
    },
  },
};
</script>

<template>
  <div>
    <div class="flex justify-center items-center mb-5 text-3xl -mx-3">
      <ActiveUsers :record="record" class="px-3" full />
      <ActiveMins :record="record" class="px-3" full />
    </div>

    <div class="flex my-3 justify-center">
      <div v-for="ribbon in ribbons" :key="ribbon.level" class="flex items-center">
        <Ribbon :level="ribbon.level" />
        <span class="text-theme text-lg ml-4 mr-6">{{ ribbon.total }}</span>
      </div>
    </div>
  </div>
</template>
