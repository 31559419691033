<script>
import '@/assets/svgs/icons/bolt.svg';

const adventures = { 1: 'space', 2: 'pirate', 3: 'jungle', 4: 'clown' };

export default {
  props: { id: {}, level: {}, small: Boolean },

  computed: {
    src() {
      return `/images/bursts/${adventures[this.id]}-icon.svg`;
    },
  },
};
</script>

<template>
  <div :class="small ? 'flex' : ''" class="fill-blank-shade items-center">
    <img :src="src" alt="" :class="small ? 'h-6' : 'h-10'" class="mr-1" />
    <div :class="small ? '' : 'mt-1'" class="flex">
      <svg :class="{ 'fill-bolt': level > 0 }" class="w-3 h-4"><use xlink:href="#bolt" /></svg>
      <svg :class="{ 'fill-bolt': level > 1 }" class="w-3 h-4"><use xlink:href="#bolt" /></svg>
      <svg :class="{ 'fill-bolt': level > 2 }" class="w-3 h-4"><use xlink:href="#bolt" /></svg>
    </div>
  </div>
</template>
