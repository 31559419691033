<script>
import AdminSpinner from '@/admin/components/AdminSpinner';
import Pagination from '@/admin/components/Pagination';
import StudentCard from '@/bursts/components/StudentCard';
import { $student } from '@/scripts/models';
import { mapGetters } from 'vuex';
import '@/assets/svgs/icons/bolt.svg';

export default {
  components: { AdminSpinner, Pagination, StudentCard },
  props: ['label', 'groupId', 'status'],
  data: () => ({ students$: $student.all(), students: [] }),

  computed: mapGetters('account', ['burstsUri']),

  methods: {
    nav(link) {
      this.fetch(link.page, false);
    },

    async fetch(page) {
      this.students = await this.students$.$get({ page, groupId: this.groupId, status: this.status });
      this.$emit('count', this.students$.totalItems);
    },
  },

  created() {
    this.fetch();
  },
};
</script>

<template>
  <AdminSpinner v-if="students.length" :res="students$" hide>
    <div class="flex items-center mb-2">
      <h2 class="head-2xl capitalize text-theme">{{ label }} Children</h2>
      <span class="title-meta">{{ students$.totalItems }}</span>
    </div>

    <div>
      <div v-if="students$.empty" class="empty">There are no {{ label }} children in this class</div>

      <div class="flex flex-wrap -mx-3">
        <div v-for="student in students" :key="student.id" class="w-1/2 px-3 my-2 xl:w-1/3">
          <StudentCard :student="student" :groupId="groupId" />
        </div>
      </div>

      <Pagination :collection="students$" @change="nav" class="mt-3" />
    </div>
  </AdminSpinner>
</template>
