<script>
const products = ['pe', 'gym', 'dance', 'foundations', 'play', 'home'];

export default {
  props: ['access', 'bursts'],

  computed: {
    products() {
      if (!this.access) return [];

      const isArray = Array.isArray(this.access);
      return products.filter(p => {
        return isArray ? this.access.includes(p) : this.access[p] && this.access[p].length;
      });
    },
  },
};
</script>

<template>
  <div>
    <div class="flex items-end -mx-0.5">
      <div v-for="slug in products" :key="slug" :class="`dot bg-${slug}`" :title="slug"></div>
      <img v-if="bursts" src="/brands/bursts.svg" alt="" class="h-3 -mb-0.5 mx-0.5" />
    </div>
  </div>
</template>

<style lang="postcss">
.dot {
  @apply rounded-full mx-0.5 h-2 w-2;
}
</style>
