<script>
import ReviewModal from '@/content/components/modal/ReviewModal';
import PhysicalModal from '@/content/components/modal/PhysicalModal';
import CardSelect from '@/content/components/partials/CardSelect';
import DifficultySelect from '@/content/components/partials/DifficultySelect';
import VimeoText from '@/content/components/partials/VimeoText';
import Hero from '@/content/components/partials/Hero';

export default {
  components: { CardSelect, ReviewModal, PhysicalModal, DifficultySelect, VimeoText, Hero },
  props: ['lesson', 'ability', 'part'],
  data: () => ({ active: 'tricky', showVideo: false }),

  computed: {
    lvl() {
      return (this.part.levels || []).find(level => level.gymLevel === this.active) || {};
    },
    skill() {
      return { label: this.lvl.label, skillWhat: this.lvl.skillWhat, skillHow: this.lvl.skillHow };
    },
  },

  methods: {
    change(level) {
      this.active = level;
      this.showVideo = false;
    },
  },
};
</script>

<template>
  <div>
    <CardSelect :lesson="lesson" v-if="part" />

    <div class="bg-white curves parts-offset">
      <div class="box-contain">
        <h1 class="box-title text-gymcard">{{ part.title }}</h1>

        <div class="box-buttons">
          <ReviewModal :ability="ability" :number="2" />
          <PhysicalModal :skill="skill" />
        </div>
      </div>

      <VimeoText v-if="lvl.video && showVideo" :vid="lvl.video" :lines="lvl.textLines" colour="gymcard" autoplay />
      <Hero :asset="lvl.heroImage" v-else />

      <DifficultySelect :hasVideo="!!lvl.video" @change="change" @video="showVideo = !showVideo" class="mt-5" />
      <div v-if="lesson.skillText" v-html="lesson.skillText" class="px-8 mb-3 rdct rdct-pupil text-center"></div>
    </div>
  </div>
</template>
