<script>
import AdminSpinner from '@/admin/components/AdminSpinner';
import WondeGroups from '@/admin/components/users/WondeGroups';
import Pagination from '@/admin/components/Pagination';
import AdminModal from '@/admin/components/AdminModal';
import ActiveMins from '@/bursts/components/ActiveMins';
import ActiveUsers from '@/bursts/components/ActiveUsers';
import Ribbons from '@/bursts/components/Ribbons';
import Vimeo from '@/global/components/Vimeo2';
import { $group } from '@/scripts/models';
import { mapGetters, mapState } from 'vuex';

export default {
  components: { AdminSpinner, AdminModal, WondeGroups, Ribbons, ActiveMins, ActiveUsers, Pagination, Vimeo },
  data: () => ({ groups$: $group.all(), groups: [], showEdit: false, showVideo: false }),

  computed: {
    canManage() {
      return (this.kind === 'create' && this.isCdSuper) || (this.kind === 'account' && this.isAdmin);
    },
    mapped() {
      return this.groups.map(group => ({ ...group, active: this.canManage || this.groupIds.includes(group.id) }));
    },

    ...mapState(['account']),
    ...mapGetters('account', ['burstsUri']),
    ...mapGetters('user', ['isCdSuper', 'isAdmin', 'groupIds', 'kind']),
  },

  methods: {
    nav(link) {
      this.fetch(link.page);
    },

    async fetch(page) {
      this.groups = await this.groups$.$get({ page, accountId: this.account.id });
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>

<template>
  <div class="container">
    <div v-if="!account.hasBursts" class="empty">
      <div class="text-2xl">This account does not have access to BURSTS</div>
    </div>
    <div v-else-if="!account.wondeBursts" class="empty">
      <div class="text-2xl">This account has not approved BURSTS on Wonde</div>
      <div v-if="account.wondeMessage" class="mt-4">"{{ account.wondeMessage }}"</div>
    </div>

    <template v-else>
      <Ribbons :record="account" />
      <hr />

      <AdminSpinner :res="groups$" hide>
        <div class="flex items-center justify-between mb-6">
          <div class="head-4xl text-bursts">BURSTS Classes</div>
          <div v-if="canManage" class="btns4">
            <button class="btn4 bg-gymcard" @click="showVideo = true">
              Classes Guide
              <svg class="btn4-icon"><use xlink:href="#video" /></svg>
            </button>
            <button class="mx-1 btn4 bg-theme" @click="showEdit = true">
              Add Classes
              <svg class="btn4-icon"><use xlink:href="#ring-add" /></svg>
            </button>
          </div>
        </div>
        <div class="flex flex-wrap -mx-2">
          <div v-for="group in mapped" :key="group.id" class="w-1/3 px-2 my-2">
            <component
              :is="group.active ? 'RouterLink' : 'div'"
              :to="`${burstsUri}groups/${group.id}`"
              class="card text-bursts"
              :class="{ outlne: group.active }"
            >
              <div class="font-semibold text-2xl mb-2">{{ group.name }}</div>

              <div class="flex items-center leading-none text-xl -mx-2">
                <ActiveUsers :record="group" class="px-2" />
                <ActiveMins :record="group" class="px-2" />
              </div>
            </component>
          </div>
        </div>

        <div v-if="groups$.empty" class="empty">No classes have been added</div>
        <Pagination :collection="groups$" @change="nav" class="mt-3" />
      </AdminSpinner>

      <AdminModal :active="showEdit" title="Import Classes" wide @close="showEdit = false">
        <WondeGroups @update="fetch()" />
      </AdminModal>

      <AdminModal :active="showVideo" title="Getting Started and Adding Classes" wide @close="showVideo = false">
        <Vimeo vid="900850130?h=42e46a13cb" class="-m-2 rounded-br-xl" />
      </AdminModal>
    </template>
  </div>
</template>
