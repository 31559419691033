<script>
import AdminSpinner from '@/admin/components/AdminSpinner';
import ExportButton from '@/admin/components/ExportButton';
import DebugLine from '@/admin/components/DebugLine';
import TrialBox from '@/global/components/TrialBox';
import InfoLine from '@/admin/components/InfoLine';
import { mapGetters, mapState } from 'vuex';
import { address } from '@/scripts/filters';

export default {
  components: { AdminSpinner, InfoLine, ExportButton, DebugLine, TrialBox },

  metaInfo() {
    return { title: this.account.name || 'Account' };
  },

  computed: {
    address() {
      return address(this.account);
    },

    ...mapState(['account']),
    ...mapGetters('account', [
      'hasHome',
      'hasTraining',
      'zohoUrl',
      'status',
      'uri',
      'wondeBursts',
      'renewalRealPe',
      'renewalBursts',
      'hasRealPeFull',
      'hasRealPeTrial',
      'hasBursts',
    ]),
    ...mapGetters('user', ['isCreate', 'isAdmin']),
  },

  methods: {
    fetch() {
      if (this.isCreate) this.$store.dispatch('account/fetch', true);
    },

    freeze() {
      this.$api(`accounts/${this.account.id}/freeze`).then(({ data }) => {
        this.$store.commit('account/patch', data);
        this.$store.commit('toast/info', `Account ${data.frozen ? 'frozen' : 'unfrozen'}`);
      });
    },
  },

  mounted() {
    if (this.isCreate) {
      if (this.$route.params.plural === 'account') this.$router.replace('/accounts');
    } else {
      if (this.$route.params.plural === 'accounts') this.$router.replace('/account');
      if (!this.isAdmin) this.$router.replace('/');
    }
    this.fetch();
  },
};
</script>

<template>
  <AdminSpinner :res="account" class="loader-lg" hide>
    <div class="container pt-8">
      <div class="lg:flex items-start shrink-0">
        <div v-if="isCreate || hasRealPeFull || hasBursts" class="order-2 shrink-0">
          <div class="flex justify-end">
            <!-- real pe menu -->
            <div class="items-center justify-center px-2 mb-4 text-center bg-white rounded flex">
              <RouterLink v-if="isCreate" :to="`${uri}`" class="ma-link exact">Membership</RouterLink>
              <RouterLink :to="`${uri}users`" class="ma-link">Users</RouterLink>
              <RouterLink v-if="hasHome" :to="`${uri}home`" class="ma-link">Home Learning</RouterLink>
              <RouterLink v-if="hasTraining" :to="`${uri}training`" class="ma-link">Training</RouterLink>
            </div>
            <!-- bursts menu -->
            <div
              v-if="isCreate && hasBursts"
              class="items-center justify-center px-2 mb-4 text-center bg-white rounded flex ml-2"
            >
              <RouterLink :to="`${uri}bursts/groups`" class="ma-link2">Classes</RouterLink>
              <RouterLink v-if="wondeBursts" :to="`${uri}bursts/year`" class="ma-link2">Year to Date</RouterLink>
            </div>
          </div>
          <div v-if="isCreate" class="btns4">
            <button v-if="isAdmin" class="btn4-clear" @click="freeze">
              {{ account.frozen ? 'Unfreeze' : 'Freeze' }} Account
            </button>
            <ExportButton v-if="isAdmin" label="Account" :path="`accounts/${account.id}`" />
            <a v-if="zohoUrl" :href="zohoUrl" class="btn4 bg-theme" target="_blank">View on Zoho</a>
          </div>
        </div>

        <!-- account info -->
        <div class="order-1 flex-grow mr-4">
          <div v-if="isCreate" class="uppercase tracking-widest leading-none font-bold mb-1 flex items-center">
            <img v-if="account.wondeRealPe" src="/images/wonde-realpe.svg" title="Wonde: real PE" class="h-4 mr-2" />
            <img v-if="account.wondeBursts" src="/images/wonde-bursts.svg" title="Wonde: BURSTS" class="h-4 mr-2" />
            <span v-if="account.frozen" class="text-fun-blue">Frozen</span>
            <template v-else>{{ status }}</template>
            <span class="mx-2">•</span>
            {{ account.accountType }}
          </div>
          <div class="trns-color text-4xl text-theme font-semibold leading-tight">
            {{ account.name }}
          </div>

          <div v-if="account.address" class="mt-1">
            {{ address }}
            <div class="-mx-0.5">
              <span v-if="account.county.name" class="mx-0.5">{{ account.county.name }}</span>
              <strong v-if="account.region.name" class="mx-0.5">{{ account.region.name }}</strong>
            </div>
          </div>
          <div v-if="!isCreate" class="text-sm mt-1">
            <div v-if="renewalRealPe">
              <strong class="w-28 inline-block">real PE Renewal:</strong> {{ renewalRealPe }}
            </div>
            <div v-if="renewalBursts">
              <strong class="w-28 inline-block">Bursts Renewal:</strong> {{ renewalBursts }}
            </div>
          </div>
        </div>

        <TrialBox v-if="!isCreate && hasRealPeTrial" class="bg-white rounded p-4 order-2 mt-4 lg:ml-4 lg:mt-0">
          <div class="text-lg font-bold text-pe mb-1">real PE Trial</div>
        </TrialBox>
      </div>

      <hr />
    </div>

    <RouterView :key="$route.path" />

    <div class="container">
      <DebugLine type="account" :object="account" />
    </div>
  </AdminSpinner>
</template>
